<template>
  <div ref="calendarMatch" class="calendar-cup-match" :class="{ 'short-version': isShortVersionVisible }">
    <div class="date" :class="{ 'short-version': isShortVersionVisible }" :style="dateStyles">
      <span class="date__dayofweek">{{ formatedDate.dayOfWeek }}</span>
      <span class="date__day">{{ gameDateTime | toFormattedDate('d') }}</span>
      <span class="date__month">{{ formatedDate.month }}</span>
    </div>
    <div class="game" :class="{ 'short-version': isShortVersionVisible }">
      <div v-if="isShortVersionVisible" class="game__container-short-version">
        <div class="game__container-short-version__score">
          <template v-if="isScoreVisible">
            <div>
              {{ game.score[0] }}
            </div>
            <div v-if="hasPenalties" class="penalties">({{ this.game.penalties_score_home_team }})</div>
          </template>
        </div>
        <div class="game__container-short-version__team">
          <div class="game__container-short-version__team__logo">
            <img :src="`https://az755631.vo.msecnd.net/teams-80/${teamLeft.team_id}.png`" alt="" />
            <div class="name">
              {{ countriesNames.get(teamLeft.team_id) || teamLeft.colloquial_name }}
            </div>
          </div>
        </div>
        <div class="game__container-short-version__vs">
          <div>
            VS
          </div>
          <div class="status">
            <div v-if="isGameLive" class="status__live">
              <div class="status__live__text">LIVE</div>
              <div class="status__live__circle" />
            </div>
            <div v-else-if="gameStatus === 4" class="status__pre">{{ gameDateTime | toFormattedDate('H:mm') }} HRS</div>
            <div v-else class="status__post">
              Finalizado
            </div>
          </div>
        </div>
        <div class="game__container-short-version__team">
          <div class="game__container-short-version__team__logo">
            <img :src="`https://az755631.vo.msecnd.net/teams-80/${teamRight.team_id}.png`" alt="" />
            <div class="name">
              {{ countriesNames.get(teamRight.team_id) || teamRight.colloquial_name }}
            </div>
          </div>
        </div>
        <div class="game__container-short-version__score">
          <template v-if="isScoreVisible">
            <div v-if="hasPenalties" class="penalties">({{ this.game.penalties_score_visiting_team }})</div>
            <div>
              {{ game.score[1] }}
            </div>
          </template>
        </div>
      </div>
      <div v-else class="game__container">
        <div class="game__container__team">
          <div class="game__container__team__logo" :class="{ 'without-score': !isScoreVisible }">
            <img :src="`https://az755631.vo.msecnd.net/teams-80/${teamLeft.team_id}.png`" alt="" />
            <div class="name">
              {{ countriesNames.get(teamLeft.team_id) || teamLeft.colloquial_name }}
            </div>
          </div>
          <div v-if="isScoreVisible" class="game__container__team__score">
            <div>
              {{ game.score[0] }}
            </div>
            <div v-if="hasPenalties" class="penalties">({{ this.game.penalties_score_home_team }})</div>
          </div>
        </div>
        <div class="game__container__vs">
          <div>
            VS
          </div>
          <div class="status">
            <div v-if="isGameLive" class="status__live">
              <div class="status__live__text">LIVE</div>
              <div class="status__live__circle" />
            </div>
            <div v-else-if="gameStatus === 4" class="status__pre">{{ gameDateTime | toFormattedDate('H:mm') }} HRS</div>
            <div v-else class="status__post">
              Finalizado
            </div>
          </div>
        </div>
        <div class="game__container__team">
          <div class="game__container__team__logo" :class="{ 'without-score': !isScoreVisible }">
            <img :src="`https://az755631.vo.msecnd.net/teams-80/${teamRight.team_id}.png`" alt="" />
            <div class="name">
              {{ countriesNames.get(teamRight.team_id) || teamRight.colloquial_name }}
            </div>
          </div>
          <div v-if="isScoreVisible" class="game__container__team__score">
            <div v-if="hasPenalties" class="penalties">({{ this.game.penalties_score_visiting_team }})</div>
            <div>
              {{ game.score[1] }}
            </div>
          </div>
        </div>
      </div>
      <div class="actions" :class="{ 'short-version': isShortVersionVisible }" @click="onClickViewStats">
        <div role="button" class="actions__info-button" @click="$emit('action')">
          {{ buttonText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCountriesNamesById } from '@/data/countries-names';
import { mapMutations, mapGetters } from 'vuex';
export default {
  name: 'CalendarMatch',
  props: {
    game: {
      type: Object,
      default: () => {},
    },
    isPremiumUser: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#fff',
    },
    predictions: {
      type: Boolean,
      default: true,
    },
    background: {
      type: String,
      default: '#DBE3E6',
    },
    backgroundLive: {
      type: String,
      default: '#77a405',
    },
    isGameLive: {
      type: Boolean,
      default: false,
    },
    time: {
      type: String,
      default: '20:30',
    },
    date: {
      type: Date,
      default: () => new Date(),
    },
    teamRight: {
      type: Object,
      required: true,
    },
    teamLeft: {
      type: Object,
      required: true,
    },
    gameStatus: {
      type: Number,
      required: true,
    },
    score: {
      type: Array,
      default: () => [],
    },
    percentages: {
      type: Array,
      default: () => [],
    },
    homeGoals: {
      type: Array,
      default: () => [],
    },
    visitingGoals: {
      type: Array,
      default: () => [],
    },
    probabilities: {
      type: [Object, null],
      default: () => null,
    },
    buttonText: {
      type: String,
      default: 'Estadísticas',
    },
    isShortVersion: {
      type: Boolean,
      default: false,
    },
    containerWidth: {
      type: Number,
      default: 0,
    },
    gameDateTime: {
      type: Date,
      default: () => new Date(),
    },
  },
  data() {
    return {
      statusOptions: {
        0: 'pending',
        1: 'live',
        2: 'finished',
      },
      probsTitles: ['GANA', 'EMPATE', 'GANA'],
      xgTitles: ['Goles', 'xG'],
      countriesNames: getCountriesNamesById(),
    };
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount']),
    hasPenalties() {
      return this.game.penalties_score_home_team || this.game.penalties_score_visiting_team;
    },
    isShortVersionVisible() {
      return this.isShortVersion && this.containerWidth > 460;
    },
    isScoreVisible() {
      return this.isGameLive || this.gameStatus !== 4;
    },
    xgTitlesReversed() {
      return this.xgTitles.map(i => i).reverse();
    },
    leftXgConent() {
      return this.homeGoals;
    },
    rightXgConent() {
      return this.visitingGoals.map(i => i).reverse();
    },
    dateStyles() {
      return {
        color: this.color,
        background: /*this.isLive ? '#77a405' :*/ '#132839',
      };
    },
    buttonStyles() {
      return {
        color: this.color,
        background: this.background,
      };
    },
    formatedDate() {
      const dayOfWeek = this.date
        .toLocaleDateString('es-ES', { weekday: 'long', timeZone: 'UTC' })
        .split(', ')[0]
        .substring(0, 3);

      const month = new Intl.DateTimeFormat('es-ES', { month: 'short', timeZone: 'UTC' })
        .format(this.date)
        .split('.')[0];

      return { month, dayOfWeek };
    },
    probsContents() {
      return this.percentages.map(p => `${p}%`);
    },
    isLive() {
      return this.gameStatus === 4 && this.isGameLive;
    },
    isPostMatch() {
      return this.gameStatus === 1 || this.gameStatus === 0;
    },
  },
  methods: {
    ...mapMutations('general', ['setPreviewSectionAnalytics']),
    onClickViewStats() {
      this.setPreviewSectionAnalytics('Calendar');
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  margin-top: 16px;
  @media screen and (max-width: 350px) {
    margin-top: 24px;
  }
}
.calendar-cup-match {
  width: 100%;
  box-sizing: content-box;
  display: flex;
  font-family: 'Avenir-Regular';

  &.short-version {
    & .date,
    & .game {
      height: 92px;
    }
  }

  & .date,
  & .game {
    height: 103px;
    border-radius: 4px;

    &.short-version {
      height: 92px;
    }
  }

  & .date {
    text-align: center;
    width: 15%;
    min-width: 44px;
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & * {
      cursor: default;
    }

    & > span {
      letter-spacing: -0.01rem;
      line-height: 1rem;
    }

    &__dayofweek,
    &__month {
      text-transform: uppercase;
      font-family: 'Avenir-Demi';
      font-size: 0.545rem;
    }

    &__day {
      font-family: 'Circular-Std';
      font-size: 1.625rem;
    }
  }

  & .game {
    display: grid;
    grid-template-columns: 1fr min-content;
    width: 82%;
    border: solid 0.8px #d2d2d2;

    &__container {
      display: grid;
      grid-template-columns: 1fr min-content 1fr;

      &__team {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        align-self: flex-start;

        &__logo {
          display: flex;
          justify-content: center;
          position: relative;
          width: 40px;
          margin-top: 14px;

          &.without-score {
            margin-top: 18px;
          }

          & > img {
            height: 40px;
            width: 40px;
          }

          & > .name {
            position: absolute;
            white-space: nowrap;
            bottom: -20px;
            font-size: 11px;
            font-family: Circular-Std-Medium, sans-serif;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;
            text-align: center;
            color: #000;
          }
        }

        &__score {
          display: flex;
          justify-content: center;
          align-self: center;
          font-family: Avenir-Pro-Bold, sans-serif;
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.75;
          letter-spacing: -0.48px;
          text-align: center;
          color: #132839;
          margin-bottom: 8px;

          & .penalties {
            font-family: Avenir-Medium, sans-serif;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.13;
            letter-spacing: -0.32px;
            text-align: center;
            color: #132839;
            margin: 0 8px;
          }
        }
      }

      &__vs {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-family: Circular-Std-Medium, sans-serif;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: -0.26px;
        text-align: center;
        color: #000;
        position: relative;

        & .status {
          width: max-content;
          position: absolute;
          top: 6px;
          font-family: Avenir-Demi, sans-serif;
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: -0.28px;
          text-align: center;
          color: #000;

          &__live {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background-color: #3c3c3a;
            padding: 0 8px;

            &__text {
              color: white;
              font-family: Avenir-Medium, sans-serif;
              font-size: 12px;
              padding-top: 2px;
              line-height: 1.2;
            }

            &__circle {
              height: 6px;
              width: 6px;
              border-radius: 50%;
              background-color: #aadd21;
              margin-left: 6px;
            }
          }
        }
      }
    }

    &__container-short-version {
      display: grid;
      grid-template-columns: 1fr min-content min-content min-content 1fr;

      &__team {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        align-self: flex-start;

        &__logo {
          display: flex;
          justify-content: center;
          position: relative;
          height: 100%;
          width: 48px;
          padding: 14px 0;

          & > img {
            height: 48px;
            width: 48px;
          }

          & > .name {
            position: absolute;
            white-space: nowrap;
            bottom: 8px;
            font-size: 14px;
            font-family: Avenir-Demi, sans-serif;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: -0.28px;
            text-align: center;
            color: #000;
          }
        }
      }

      &__vs {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        align-self: flex-end;
        font-size: 27px;
        font-family: Circular-Std-Bold, sans-serif;
        font-weight: bold;
        margin: 0 30px;
        position: relative;

        & > div:first-child {
          margin-bottom: 8px;
        }

        & .status {
          width: max-content;
          position: absolute;
          top: 6px;
          font-family: Avenir-Demi, sans-serif;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: -0.28px;
          text-align: center;
          color: #000;

          &__live {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            background-color: #3c3c3a;
            padding: 0 8px;

            &__text {
              color: white;
              font-family: Avenir-Medium, sans-serif;
              font-size: 12px;
              padding-top: 2px;
              line-height: 1.2;
            }

            &__circle {
              height: 6px;
              width: 6px;
              border-radius: 50%;
              background-color: #aadd21;
              margin-left: 6px;
            }
          }
        }
      }

      &__score {
        align-self: center;
        display: flex;
        justify-content: center;
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.72;
        letter-spacing: -0.5px;
        text-align: center;
        color: #132839;

        & .penalties {
          font-family: Avenir-Medium, sans-serif;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.13;
          letter-spacing: -0.32px;
          text-align: center;
          color: #132839;
          margin: 0 8px;
        }
      }
    }
  }

  & .actions {
    height: 100%;
    display: flex;
    align-items: flex-start;

    &__info-button {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      font-size: 11px;
      font-family: Avenir-Demi, sans-serif;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.5;
      letter-spacing: -0.32px;
      text-align: center;
      color: #132839;
      margin: 8px 8px 0;
      background: rgb(254, 208, 37);
      background: linear-gradient(180deg, rgba(254, 208, 37, 1) 0%, rgba(240, 178, 17, 1) 100%);
      width: 40px;
      height: 23px;
      padding: 0.4rem;
      border-radius: 3.6px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.19);
      cursor: pointer;
    }

    &.short-version {
      align-items: center;

      & .actions__info-button {
        width: 70px;
        height: 42px;
        font-size: 16px;
        font-family: Avenir-Demi, sans-serif;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.5;
        letter-spacing: -0.32px;

        @media screen and (max-width: 550px) {
          width: 42px;
          height: 28px;
          font-size: 10px;
          margin-left: 0;
        }
      }
    }
  }
}
</style>
