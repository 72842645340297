const countriesNames = [
  { id: 392, name: 'México' },
  { id: 393, name: 'Dinamarca' },
  { id: 404, name: 'Argentina' },
  { id: 408, name: 'Brasil' },
  { id: 434, name: 'Estados Unidos' },
  { id: 438, name: 'Perú' },
  { id: 468, name: 'Nigeria' },
  { id: 473, name: 'Panamá' },
  { id: 474, name: 'Costa Rica' },
  { id: 477, name: 'Japón' },
  { id: 478, name: 'Canadá' },
  { id: 562, name: 'Arabia Saudita' },
  { id: 637, name: 'Corea del Sur' },
  { id: 653, name: 'Australia' },
  { id: 704, name: 'España' },
  { id: 706, name: 'Croacia' },
  { id: 707, name: 'Islandia' },
  { id: 708, name: 'Suiza' },
  { id: 709, name: 'Camerún' },
  { id: 710, name: 'Túnez' },
  { id: 711, name: 'Portugal' },
  { id: 721, name: 'Bélgica' },
  { id: 724, name: 'Uruguay' },
  { id: 730, name: 'Colombia' },
  { id: 731, name: 'Países Bajos' },
  { id: 732, name: 'Alemania' },
  { id: 734, name: 'Francia' },
  { id: 735, name: 'Ecuador' },
  { id: 736, name: 'Ghana' },
  { id: 739, name: 'Egipto' },
  { id: 742, name: 'Gales' },
  { id: 744, name: 'Serbia' },
  { id: 748, name: 'Inglaterra' },
  { id: 749, name: 'Irán' },
  { id: 751, name: 'Rusia' },
  { id: 866, name: 'Senegal' },
  { id: 2243, name: 'Polonia' },
  { id: 2271, name: 'Suecia' },
  { id: 2527, name: 'Qatar' },
  { id: 2599, name: 'Marruecos' },
];

export function getCountriesNamesById() {
  return countriesNames.reduce((accumulator, country) => {
    accumulator.set(country.id, country.name);
    return accumulator;
  }, new Map());
}
